import type { CustomBlockMenuItem, GlossaryMenuItem, CustomBlocksMenuItem } from './types';
import type { ReadCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { Dispatch, RefObject } from 'react';
import type { RangeRef } from 'slate';

import fuzzysort from 'fuzzysort';
import React, { createContext, useContext, useReducer, useMemo, useState, useEffect } from 'react';

import { useReadmeApiInfinite } from '@core/hooks/useReadmeApi';

import type { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import { MenuConfigActionTypes, MenuHandleTypes } from '@ui/MarkdownEditor/enums';
import type { Props } from '@ui/MarkdownEditor/types';

import { bounded } from '../utils';
import { sampleData as variableSampleData } from '../VariableMenu/sampleData';

import { sampleData } from './sampleData';

const sampleGlossaryTerms = variableSampleData
  .filter(term => term.type === 'Glossary Term')
  .map(term => ({
    _id: term.id,
    term: term.name,
    definition: term.definition,
  }));

interface InitAction {
  payload: { menuType?: MenuHandleTypes; rangeRef: RangeRef };
  type: MenuActionTypes.init;
}

interface OpenAction {
  payload: {
    target: RefObject<HTMLElement>;
  };
  type: MenuActionTypes.open;
}

interface UpAction {
  type: MenuActionTypes.up;
}

interface DownAction {
  type: MenuActionTypes.down;
}

interface CloseAction {
  type: MenuActionTypes.close;
}

interface SearchAction {
  payload: string;
  type: MenuActionTypes.search;
}

interface RevalidateAction {
  type: MenuConfigActionTypes.revalidate;
}

interface LoadNextPageAction {
  type: MenuConfigActionTypes.loadNextPage;
}

interface DataLoadedAction {
  payload: CustomBlocksMenuItem[];
  type: MenuConfigActionTypes.dataLoaded;
}

interface ShortcutAction {
  payload: boolean;
  type: MenuConfigActionTypes.shortcut;
}

type CustomBlocksMenuAction =
  | CloseAction
  | DataLoadedAction
  | DownAction
  | InitAction
  | LoadNextPageAction
  | OpenAction
  | RevalidateAction
  | SearchAction
  | ShortcutAction
  | UpAction;

interface CustomBlocksMenuState {
  customBlocks: CustomBlockMenuItem[];
  filtered: CustomBlocksMenuItem[];
  glossaryTerms: GlossaryMenuItem[];
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  menuType: MenuHandleTypes;
  open: boolean;
  rangeRef: RangeRef | null;
  search: string | null;
  selected: number;
  shortcut: boolean;
  target: RefObject<HTMLElement> | null;
}

interface CustomBlocksMenuReducer {
  (state: CustomBlocksMenuState, action: CustomBlocksMenuAction): CustomBlocksMenuState;
}

type ProjectData = Required<
  Pick<Props, 'basic' | 'glossaryTerms' | 'subdomain' | 'useMDX' | 'useTestData' | 'version'>
> & {
  disallowCustomBlocks: boolean;
  useReusableContent: boolean;
};

const initialState: CustomBlocksMenuState = {
  customBlocks: [],
  filtered: [],
  glossaryTerms: [],
  hasNextPage: false,
  isNextPageLoading: false,
  menuType: MenuHandleTypes.reusableContent,
  open: false,
  rangeRef: null,
  search: null,
  selected: 0,
  shortcut: false,
  target: null,
};

/**
 * When we use the `useReadmeApiInfinite` hook to fetch Reusable Content blocks,
 * we get back an array of pages in `state.data`. This function will flatten that
 * array into a single array of items.
 */
const transformDataToItems = (data: ReturnType<typeof useReadmeApiInfinite<ReadCollectionType>>['data']) =>
  data?.flatMap(page => page?.data || []) || [];

const PER_PAGE = 20;

const useCustomBlocksMenuReducer = ({
  basic,
  disallowCustomBlocks,
  glossaryTerms,
  subdomain,
  useMDX,
  useReusableContent,
  useTestData,
  version,
}: ProjectData) => {
  const [customBlocks, setCustomBlocks] = useState<CustomBlockMenuItem[]>([]);

  const {
    data: customBlocksData,
    isLoading,
    mutate,
    size,
    setSize,
  } = useReadmeApiInfinite<ReadCollectionType>((pageIndex, previousPageData) => {
    if (disallowCustomBlocks || !useReusableContent) return null;

    if (previousPageData?.paging?.next === null) {
      // Reached the end of the list
      return null;
    }

    // if Reusable Content is disabled, we only want to fetch components
    let reusableContentTypeUrlParam = '';
    if (!useReusableContent) {
      reusableContentTypeUrlParam = '&type=component';
    }
    if (useReusableContent && !useMDX) {
      reusableContentTypeUrlParam = '&type=content';
    }

    return `/${subdomain}/api-next/v2/versions/${version}/custom_blocks?page=${
      pageIndex + 1
    }&per_page=${PER_PAGE}&sort=name${reusableContentTypeUrlParam}`;
  });

  useEffect(() => {
    if (disallowCustomBlocks || !useReusableContent) return;

    if (useTestData) {
      setCustomBlocks(sampleData);
      return;
    }

    if (basic || !subdomain || !version) return;

    if (!isLoading) {
      // Flatten the array of pages into a single array of items and sort them by type (component or content)
      const content = transformDataToItems(customBlocksData).sort((a, b) => a.type.localeCompare(b.type));
      if (useMDX) {
        setCustomBlocks([...content]);
      } else {
        setCustomBlocks(content);
      }
    }
  }, [
    customBlocksData,
    basic,
    subdomain,
    useTestData,
    version,
    isLoading,
    useMDX,
    useReusableContent,
    disallowCustomBlocks,
  ]);

  const hasNextPage = useMemo(() => {
    if (!customBlocksData) return false;
    const endPage = customBlocksData[customBlocksData.length - 1];

    return endPage.paging?.next !== null;
  }, [customBlocksData]);

  const memoized: [CustomBlocksMenuReducer, CustomBlocksMenuState] = useMemo(() => {
    const terms = (useTestData ? sampleGlossaryTerms : glossaryTerms).map(
      (term: ProjectData['glossaryTerms'][0]): GlossaryMenuItem => ({
        ...term,
        search: `glossary:${term.term}`,
      }),
    );

    const initial = {
      ...initialState,
      customBlocks,
      glossaryTerms: terms,
      filtered: useMDX ? [...customBlocks, ...terms] : customBlocks,
      hasNextPage,
    };

    const reducer: CustomBlocksMenuReducer = (state, action) => {
      switch (action.type) {
        case 'init':
          return { ...initial, shortcut: state.shortcut, ...action.payload };
        case 'open':
          if (state.open) return state;
          return { ...state, target: action.payload.target, open: true };
        case 'up':
          return { ...state, selected: bounded(state.selected - 1, state.filtered.length) };
        case 'down':
          return { ...state, selected: bounded(state.selected + 1, state.filtered.length - 1) };
        case 'shortcut':
          return { ...state, shortcut: action.payload };
        case 'loadNextPage': {
          setSize(size + 1);
          return state;
        }
        case 'dataLoaded': {
          return {
            ...state,
            customBlocks,
            filtered: state.search
              ? fuzzysort
                  .go(state.search, customBlocks, {
                    keys: ['name'],
                  })
                  .map((r: Fuzzysort.KeysResult<CustomBlockMenuItem>) => r.obj)
              : customBlocks,
            hasNextPage,
          };
        }
        case 'search': {
          const search = action.payload ? action.payload.replace(/^</, '') : '';
          const set = useMDX
            ? state.menuType === MenuHandleTypes.glossary
              ? state.glossaryTerms
              : [...state.customBlocks, ...state.glossaryTerms]
            : state.customBlocks;

          return search === state.search
            ? state
            : {
                ...state,
                search,
                selected: 0,
                filtered: search
                  ? fuzzysort
                      .go(search, set, {
                        keys: ['name', 'search'],
                      })
                      .map((r: Fuzzysort.KeysResult<CustomBlocksMenuItem>) => r.obj)
                  : set,
              };
        }
        case 'revalidate':
          mutate();
          return state;
        case 'close':
          state.rangeRef?.unref();
          return {
            ...state,
            open: false,
            shortcut: false,
            target: null,
            rangeRef: null,
          };
        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown action in useReusableContent');
          return state;
      }
    };

    return [reducer, initial];
  }, [glossaryTerms, hasNextPage, mutate, customBlocks, setSize, size, useMDX, useTestData]);

  const [customBlocksMenuState, dispatch] = useReducer(...memoized);

  // Update state when reusableContent is loaded.
  // This will occur for paginated requests after the initial data is loaded.
  useEffect(() => {
    dispatch({ type: MenuConfigActionTypes.dataLoaded, payload: customBlocks });
  }, [customBlocks]);

  return useMemo<[CustomBlocksMenuState, Dispatch<CustomBlocksMenuAction>]>(
    () => [customBlocksMenuState, dispatch],
    [customBlocksMenuState],
  );
};

const CustomBlocksMenuContext = createContext([initialState, () => {}] as [
  CustomBlocksMenuState,
  Dispatch<CustomBlocksMenuAction>,
]);

export const CustomBlocksMenuProvider = ({
  basic,
  children,
  glossaryTerms,
  subdomain,
  useMDX = false,
  useReusableContent = true,
  disallowCustomBlocks = false,
  useTestData = false,
  version = '1.0',
}: ProjectData & { children: React.ReactNode }) => {
  const value = useCustomBlocksMenuReducer({
    basic,
    disallowCustomBlocks,
    glossaryTerms,
    subdomain,
    useMDX,
    useReusableContent,
    useTestData,
    version,
  });

  /**
   * @note
   * We want to show the reusable content menu for glossary terms when MDX is enabled, even if custom blocks are disabled.
   * This is because glossary terms are rendered in the RC menu, and we want to allow users to insert them into the editor.
   * ex: from the Changelog
   */
  const showCustomBlocksMenu = (!disallowCustomBlocks && !basic) || useMDX;

  return showCustomBlocksMenu ? (
    <CustomBlocksMenuContext.Provider value={value}>{children}</CustomBlocksMenuContext.Provider>
  ) : (
    <>{children}</>
  );
};

export const useCustomBlocksMenu = () => useContext(CustomBlocksMenuContext);
export default useCustomBlocksMenu;
